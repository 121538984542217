<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <h1 class="mb-3 text-center">New Scan</h1>

        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-card class="pa-4">
            <v-card-text>
              <v-select
                v-model="site"
                :rules="rules.site"
                label="Site"
                name="site"
                :items="sites"
                item-text="name"
                item-value="id"
                persistent-hint
                return-object
              >
                <template v-slot:item="{ item }">
                  <span class="d-flex flex-column">
                    <span>{{ item.name }}</span>
                    <span class="text-caption text--secondary mt-n1">
                      {{ item.baseUrl }}
                    </span>
                  </span>
                </template>
              </v-select>

              <v-text-field
                v-model="form.baseUrl"
                label="Base URL"
                name="baseUrl"
                disabled
              ></v-text-field>

              <v-text-field
                v-model="form.depth"
                :rules="rules.depth"
                label="Crawl Depth"
                name="depth"
                type="number"
                hint="The maximum depth to crawl the website"
                persistent-hint
              >
                <template v-slot:append-outer>
                  <v-dialog
                    v-model="depthHelpDialog"
                    scrollable
                    width="800"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card class="pa-4">
                      <v-card-title>What is Crawl Depth?</v-card-title>
                      <v-card-text>
                        <p>Crawl depth refers to how far down into the website's page hierarchy the web crawler will traverse. The base URL is at the top of the hierarchy with a depth of 0, any pages directly linked from the base URL have a depth of 1, and so on.</p>
                        <p>
                          This is what the first few crawl depth look like:
                          <ul>
                            <li>0 - Scan the base URL only.</li>
                            <li>1 - Scan the base URL and all pages linked from the base URL.</li>
                            <li>2 - Scan the base URL, all pages linked from the base URL, and all pages link from those pages.</li>
                          </ul>
                        </p>
                        <p>It can generally be assumed that the closer a page is to the homepage, the more people that will visit it, and therefore the more significant it is. Because of that, <b>when doing initial accessibility evaluations of websites, it is usually sufficient to do a scan with just a depth of 1</b>. If you want to instead get a full report of the website, set the depth to 10 and the crawler will traverse every page on the website (except for in a few rare cases).</p>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          text
                          @click="depthHelpDialog = false"
                        >Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-text-field>

              <v-divider class="my-4"></v-divider>

              <h3>Crawl Options</h3>
              <v-checkbox
                label="Include query parameters"
                v-model="form.options.includeQueryParams"
                hint="Example: example.com/blog?category=news&page=2"
                persistent-hint
              ></v-checkbox>
              <v-checkbox
                label="Include anchors"
                v-model="form.options.includeHashes"
                hint="Example: example.com/#about"
                persistent-hint
              ></v-checkbox>
              <v-checkbox
                label="Include subdomains"
                v-model="form.options.includeSubdomains"
                hint="Include all subdomains in the scan"
                persistent-hint
              ></v-checkbox>

              <v-divider class="my-4"></v-divider>

              <h3>Scan Options</h3>
              <div style="margin: 1rem 0">
                  <label class='v-label'>Schedule For:</label>
                  <br/>
                  <date-picker :disabled-date="disabledBeforeToday" :showSecond="false" v-model="form.timestamp" value-type="timestamp" type="datetime"/>
              </div>
<!--              <v-checkbox
                label="Capture Broken Links"
                v-model="form.options.brokenLinks"
                ></v-checkbox> -->
              <v-checkbox
                label="Perform accessibility scan"
                v-model="form.options.accessibility"
                hint="The scan will only generate an inventory of the site if this is left unchecked"
                persistent-hint
              ></v-checkbox>
              <v-checkbox
                label="Generate full report PDF"
                v-if="form.options.accessibility"
                v-model="form.options.generateFullReportPDF"
              ></v-checkbox>
              <v-checkbox
                label="Email when scan is complete"
                v-model="form.options.notifyUserOnFinish"
              ></v-checkbox>
              <v-text-field hidden="true" name="email" v-model="form.options.email" />
              <v-alert
                v-model="showError"
                dense
                outlined
                dismissible
                type="error"
              >{{errorMessage}}</v-alert>
            </v-card-text>

            <v-card-actions>
              <v-btn
                type="submit"
                color="primary"
                class="mr-2"
                :disabled="!valid"
                :loading="submitting"
              >Start</v-btn>

              <v-btn
                color="error"
                plain
                @click="$router.back()"
              >Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Datepicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css';


  export default {
    title: 'Create Scan',

    components: {'date-picker': Datepicker},

    props: ['siteId'],

    data: function() {
      return ({
        editing: false,
        valid: false,
        site: null,
        form: {
          siteId: '',
          title: '',
          baseUrl: '',
          depth: '0',
          timestamp: null,
          options: {
            email: '',
            brokenLinks: false,
            accessibility: true,
            includeHashes: false,
            includeQueryParams: false,
            includeSubdomains: false,
            generateFullReportPDF: false,
            notifyUserOnFinish: false,
          },
        },
        rules: {
          depth: [
            v => !!v || 'Crawl depth is required',
            v => v >= 0 && v <= 10 || 'Crawl depth must be a value between 0 and 10'
          ],
          site: [
            v => !!v || 'Site selection is required',
          ]
        },
        showError: false,
        errorMessage: '',
        depthHelpDialog: false
      })
    },

    computed: {
      ...mapGetters({
        submitting: 'scans/submitting',
        updatedData: 'scans/updatedData',
        sites: 'sites/siteList',
        user: 'auth/currentUser'
      })
    },

    watch: {
      site: function() {
        if (this.site) {
          this.form.siteId = this.site.id
          this.form.title = this.site.name
          this.form.baseUrl = this.site.baseUrl
        } else {
          this.form.siteId = ''
          this.form.title = ''
          this.form.baseUrl = ''
        }
      },
      updatedData: function() {
        if (this.updatedData !== null && !this.submitting) {
          // Reset the form
          this.$refs.form.reset()

          // Show success snackbar
          this.setSnackbar({
            message: `Scan started successfully!`
          })

          // Route to new scan
          this.$router.push({ name: 'site-details', params: { id: this.updatedData.siteId }})
        }
      }
    },

    created: async function() {
      if (this.siteId) {
        this.site = this.sites.find(site => site.id === this.siteId)
      }
    },

    beforeRouteEnter: async function(to, from, next) {
      // If navigating here from site details, select the proper site
      if (from.name === 'site-details') {
        to.params.siteId = from.params.id
      }

      await next(async vm => {
        vm.setLoading(true)
        await vm.getSites()
        vm.setLoading(false)
      })
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setSnackbar: 'setSnackbar',
        getSites: 'sites/getSites',
        storeScan: 'scans/storeScan',
        getUser: 'auth/currentUser'
      }),
      onSubmit: async function() {
        if(this.form.options.notifyUserOnFinish) {
          const user = await this.getUser();
          this.form.options.email = user.signInUserSession.idToken.payload.email;
        }
        if(!this.form.timestamp)
            this.form.timestamp = new Date().getTime()
        this.storeScan(this.form)
      },
        disabledBeforeToday: function(date) {
            const today = new Date();
            //today.setHours(0,0,0,0);

            return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
        }
    }
  }
</script>
